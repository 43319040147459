.IconCard {
	width: 100%;
	height: 100%;
}
.TitleCard {
	font-size: 19pt;
}
.IconCardBox {
	width: 50px;
	height: 50px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	margin-bottom: 10px;
	padding: 10px;
}
.Desc {
	font-size: 17px;
	font-weight: 300;
	margin-bottom: 50px;
}
