.BgLayout {
	background: conic-gradient(
		from 154deg at 50% 50%,
		#dd0064 0deg,
		#19274f 360deg
	);
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	overflow-y: auto;
	padding: 50px 0px;
}
.BoxContent {
	width: 45%;
}
.LogoForm {
	width: 60%;
	margin: auto;
}
.LoadingBtnIcon {
	width: 18px;
	height: 18px;
	margin-right: 5px;
}

@media (max-width: 992px) {
	.BoxContent {
		width: 60%;
	}
}

@media (max-width: 665px) {
	.BoxContent {
		width: 80%;
	}
}
