.SliderBox {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	overflow-x: hidden;
}
.Content {
	display: flex;
	white-space: nowrap;
	/* overflow-x: scroll; */
	padding-bottom: 10px;
	z-index: 888;
	transition-duration: 300ms;
	margin-right: 20px;
}
.SliderBox::-webkit-scrollbar {
	display: none;
}
.Navigator {
	display: flex;
	flex-direction: row;
	position: absolute;
	margin-top: 20px;
	left: 0;
	width: 100%;
	height: 312px;
	padding: 50px;
	padding-left: 0;
	padding-right: 0;
}

.ButtonNav {
	margin-top: auto;
	margin-bottom: auto;
	color: rgb(255, 255, 255);
	background-color: black;
	opacity: 0.3;
	z-index: 999;
	width: 50px;
	height: 50px;
	font-size: 30px;
	border-radius: 8px;
	padding: 10px;
}

.ButtonNav:hover {
	opacity: 0.9;
	border: 1px solid #fff;
}

.ButtonNavLeft {
	margin-right: auto;
	margin-left: 0px;
	z-index: 999;
}

.ButtonNavRight {
	margin-left: auto;
	margin-right: 0px;
	z-index: 999;
}

.ButtonNavLeft svg {
	transform: rotate(0deg);
	margin-top: -25px;
}

.ButtonNavRight svg {
	transform: rotate(180deg);
	margin-top: -25px;
}

@media (max-width: 992px) {
	.Navigator {
		padding: 20px;
		padding-left: 0;
		padding-right: 0;
	}
}
