.AddBtn2,
.ContentData2 {
	width: 100%;
	height: 160px;
	border-radius: 8px !important;
}

.ContentData2 {
	background-size: cover !important;
	background-position: center;
}

.Navigator2 {
	border-radius: 0rem 0rem 8px 8px;
}

.ContentData2:hover {
	.Navigator2 {
		background: linear-gradient(360deg, black, transparent);
		/* background-color: #ca0c64; */
	}
	.CityName {
		background: linear-gradient(360deg, transparent, rgba(0, 0, 0, 0.229));
	}
}

.PopUpAdd {
	width: 80%;
	max-width: unset;
}

@media (max-width: 760px) {
	.AddBtn2,
	.ContentData2 {
		height: 100px !important;
	}
	.PopUpAdd {
		width: auto;
	}
}
