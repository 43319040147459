.JumboTitle {
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 48px;
	font-weight: 800;
	gap: 20px;
}
.JumboTitleSpecial {
	color: #dd0064;
	-webkit-text-stroke: 2px #fff;
}
.JumboDescription {
	font-size: 24px;
	text-align: center;
	line-height: 36px;
	margin-top: 40px;
}
.JumboChip {
	background-color: rgba(255, 255, 255, 0.15);
	color: #fff;
	padding: 10px 15px;
	border-radius: 999px;
	position: absolute;
	font-size: 15px;
	font-weight: 600;
}
.JumboOnline {
	top: 14%;
	right: 27%;
	transform: rotateZ(-20deg);
}
.JumboOnsite {
	top: 22%;
	right: 20%;
	transform: rotateZ(20deg);
}
.JumboHybrid {
	top: 34%;
	right: 24%;
	transform: rotateZ(-15deg);
}
.JumboButtonArea {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin-top: 40px;
}
.JumboButton {
	padding: 12px 20px;
	background-color: #fff;
	color: #121212;
	border-radius: 6px;
	border: none;
	align-items: center;
	flex-direction: row;
	gap: 10px;
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 800;
}

.CategoryArea,
.CategoryItem {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.CategoryArea {
	flex-wrap: wrap;
	gap: 20px;
}
.CategoryItem {
	box-shadow: 1px 1px 5px 1px #ddd;
	padding: 10px;
	border-radius: 8px;
	gap: 10px;
	flex-basis: 23%;
	flex-grow: 1;
	display: flex;
}
.CategoryName {
	font-size: 20px;
	color: #2c2c2c;
	font-weight: 700;
}
.CategoryEvents {
	font-size: 12px;
	color: #a7a7a7;
	margin-top: 5px;
}

.ScrollContainer {
	display: flex;
	white-space: nowrap;
	overflow-x: scroll;
	padding-bottom: 10px;
}
.DesktopEOL {
	display: block;
}
.EventCard {
	width: unset !important;
	flex-basis: 31.8% !important;
}
.ButtonBasic {
	width: unset;
	display: flex;
	padding: 10px 15px;
}

.ButtonBasic svg {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 10px;
}

.ButtonBasic div {
	/* margin-top: -2.5px; */
	margin: 0;
}
.CustomSpotlight {
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	scale: 1;
}
.CustomSpotBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-size: cover;
	background-position: center;
}
.CustomSpotText {
	width: 45%;
	height: 100%;
	padding-top: 92px;
	padding-bottom: 92px;
	padding-left: 76px;
}
.CustomSpotTitle {
	width: 100%;
	color: #fff;
	/* font-family: Inter; */
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 16px;
}
.CustomSpotSubtitle {
	/* font-family: Inter; */
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	color: #fff;
	margin-bottom: 16px;
}
.CustomSpotEvents {
	width: 45%;
	height: 100%;
	padding-top: 92px;
	padding-bottom: 92px;
	margin-left: auto;
	padding-left: 40px;
}
.CustomSpotlight .ButtonBasic {
	width: 150px;
}
.CustomNavSlideSpot {
	padding: 0 !important;
	padding-left: 40px !important;
	width: 45% !important;
	right: 0;
	left: unset !important;
}
.SelectedEvent {
	width: 100%;
	display: flex;
	text-align: center;
}
.SelectedEvent .ButtonBasic {
	width: 150px;
	margin: auto;
	margin-top: 24px !important;
	text-align: center;
}
.SelectedEvent .ButtonBasic div {
	width: 100%;
	text-align: center;
}
.FrontBanner {
	display: flex;
	padding: 0;
	overflow: hidden;
}
.FrontBannerInner {
	list-style: none;
	min-width: 100%;
}
.FrontBannerInner div {
	padding: 0;
	margin: 0;
	list-style: none;
	display: block;
}
.FrontBanner img {
	width: 100%;
	height: 100px;
	object-fit: cover;
	object-position: center;
	border: 0;
}
.CustomNavCarousel {
	height: 160px !important;
}
.AbsoluteBg {
	position: absolute;
	height: 500px;
	z-index: -1;
}
.JumboSec {
	display: flex;
	width: 100%;
	flex-direction: row;
	color: white;
	margin-top: 100px;
}
.JumboSecL {
	padding-left: 50px;
	padding-right: 10px;
	width: 50%;
	margin-bottom: 80px;
}
.JumboSecR {
	padding-left: 50px;
	width: 50%;
	overflow: hidden;
	display: flex;
}
.JumboSecL div {
	text-align: left !important;
	margin-bottom: 16px;
	margin-top: 0px;
}
.JumboSecL button {
	margin-top: unset;
}
.JumboSecR img {
	width: 730px;
	margin-top: 40px;
	margin-left: auto;
}
.Col2 {
	width: 50%;
	margin-top: auto;
	margin-bottom: auto;
	color: black;
	display: flex;
}
.Col2 div {
	text-align: left !important;
	margin-bottom: 16px;
	margin-top: 0px;
}
.Col2 button {
	margin-top: unset;
}
.FrontCover2 {
	width: 100%;
	border-radius: 7px;
	background: lightgray 50% / cover no-repeat;
	box-shadow: 0px 53px 93px 0px rgba(95, 7, 50, 0.17);
}

@media (max-width: 992px) {
	.CustomSpotBox {
		display: flex;
		flex-direction: column;
		gap: 20;
	}
	.CustomSpotText {
		width: 100%;
		padding-bottom: 10px;
		padding-top: 30px;
		padding-left: 40px;
		padding-right: 40px;
	}
	.CustomSpotEvents {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 20px;
	}
	.CustomNavSlideSpot {
		width: 100% !important;
	}
	.CustomNavCarousel {
		padding: 20px !important;
	}
	.AbsoluteBg {
		height: 645px;
	}
	.JumboSec {
		flex-direction: column;
	}
	.JumboSecL {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}
	.JumboSecR {
		width: 100%;
	}
	.JumboSecR img {
		width: 530px;
		margin-top: unset;
	}
	.Col2 {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}
}

@media (max-width: 760px) {
	.CustomNavCarousel {
		height: 100px !important;
	}
}

@media (max-width: 550px) {
	.DesktopEOL {
		display: none;
	}
	.JumboTop {
		padding: 100px 20px;
	}
	.JumboTitle {
		font-size: 22px;
		gap: 10px;
		flex-direction: row;
	}
	.JumboChip {
		font-size: 12px;
		opacity: 0.7;
		/* display: none; */
	}
	.JumboDescription {
		font-size: 16px;
		line-height: 28px;
	}
	.JumboOnline {
		top: 10%;
		right: 28%;
	}
	.JumboOnsite {
		top: 12%;
		right: 8%;
	}
	.JumboHybrid {
		top: 27%;
		right: 6%;
	}
	.JumboButtonArea {
		flex-direction: column;
		/* width: 100%; */
	}
	.JumboButton {
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		padding: 16px 30px;
	}
	.CustomSpotTitle {
		font-size: 22px;
	}
	.CustomSpotSubtitle {
		font-size: 16px;
	}
}
