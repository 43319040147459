body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f2f2f2 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* overide style bootstrap */
.btn-primary {
	background-color: #ca0c64 !important;
	border: 1px solid #ca0c64 !important;
}

.btn-primary:hover {
	background-color: #a70a53 !important;
	border: 1px solid #a70a53 !important;
}
