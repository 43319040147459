.ErrorPage{
	width: 100%;
	height: 100%;
	display: flex;
}
.ErrorPage div{
	margin: auto;
	gap: 10px;
	display: flex;
	flex-direction: column;
}
.ErrorPage svg{
	margin: auto;
	font-size: 100px;
	color: red;
}
.GroupBox{
	background-color: #fff;
	border-radius: 12px;
	padding: 20px;
	box-shadow: 0 0 20px 0px #6160600d;
	overflow-y: hidden;
	max-height: 550px;
}
.GroupBox h6{
	font-weight: 700;
}
.ReloadBtn{
	bottom: 20px;
	right: 20px;
	width: unset;
}