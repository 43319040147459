.ErrorPage{
	width: 100%;
	height: 100%;
	display: flex;
}
.ErrorPage div{
	margin: auto;
	gap: 10px;
	display: flex;
	flex-direction: column;
}
.ErrorPage svg{
	margin: auto;
	font-size: 100px;
	color: red;
}